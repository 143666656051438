module.exports.alerts = {
  state: () => ({
    showOverlay: false,
    showProgress: false,
    showDialog: false,
    dialogText: "",
    dialogColor: "",
    showSnackBar: false,
    snackBarText: "",
    snackBarText2: "",
    notifications: [],
  }),
  getters: {
    getShowOverlay: (state) => {
      return state.showOverlay;
    },
    getshowProgress: (state) => {
      return state.showProgress;
    },
    getshowDialog: (state) => {
      return state.showDialog;
    },
    getdialogText: (state) => {
      return state.dialogText;
    },
    getdialogColor: (state) => {
      return state.dialogColor;
    },
    getshowSnackBar: (state) => {
      return state.showSnackBar;
    },
    getsnackBarText: (state) => {
      return state.snackBarText;
    },
    getsnackBarText2: (state) => {
      return state.snackBarText2;
    },
  },
  mutations: {
    ["SHOW_OVERLAY"](state, show) {
      state.showOverlay = show;
    },
    ["SHOW_PROGRESS"](state, show) {
      state.showProgress = show;
    },
    ["SHOW_DIALOG"](state, { show, text, color }) {
      state.showDialog = show;
      state.dialogText = text;
      state.dialogColor = color;
    },
    ["SHOW_SNACKBAR"](state, { show, text, text2 }) {
      state.showSnackBar = show;
      state.snackBarText = text;
      state.snackBarText2 = text2;
    },
  },
  actions: {
    setProgress({ commit }) {
      commit("SHOW_DIALOG", { show: false, text: "", color: "" });
      commit("SHOW_OVERLAY", true);
      commit("SHOW_PROGRESS", true);
    },
    setDialog({ commit }, { show, text, color }) {
      commit("SHOW_OVERLAY", true);
      commit("SHOW_PROGRESS", false);
      commit("SHOW_DIALOG", { show, text, color });
    },
    unsetOverlay({ commit }) {
      commit("SHOW_OVERLAY", false);
      commit("SHOW_PROGRESS", false);
      commit("SHOW_DIALOG", { show: false, text: "", color: "" });
    },
    setSnackBar({ commit }, text, text2 = "") {
      commit("SHOW_SNACKBAR", { show: true, text, text2 });
    },
    unsetSnackBar({ commit }) {
      commit("SHOW_SNACKBAR", { show: false, text: "", text2:"" });
    },
  },
};

<template>
  <v-form ref="form" v-model="valid" lazy-validation class="d-flex justify-center" style="flex-direction: column;">
    <h4 class=" text-center text-uppercase black--text py-5">Forgot Password?</h4>
    <v-text-field v-model="email" :rules="emailRules" label="Email" required solo dense></v-text-field>

    <v-btn style="color:#fcfcfc;" color="primary" class="my-4" @click="validate" :disabled="!valid || requestSent">
      Change Password
    </v-btn>
    <div class="small-font">{{ status }}</div>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    email: "",
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],

    status: "",
    valid: true,
    requestSent: false,
  }),

  methods: {
    validate() {
      let validated = this.$refs.form.validate();

      if(!validated){
        this.status = "Invalid email"
        return
      }
      // sending request to api
      this.requestSent = true;
      this.axios
        .post("/api/users/forgot", {
          email: this.email,
        })
        .then((res) => {
          
          this.requestSent = false;
          this.status = res.data.message;
          if (res.data.success) {
            this.$router.push({ name: "ForgotEmail" });
          }
        })
        .catch((error) => {
          console.log(error);
          this.requestSent = false;
          this.status = "Your request could not be processed - kindly check your internet connection";
        });
    },
  },
};
</script>

<style scoped>
::v-deep .small-font,
::v-deep .v-label,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}
</style>

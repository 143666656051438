import { render, staticRenderFns } from "./Forgot.vue?vue&type=template&id=40abd44b&scoped=true"
import script from "./Forgot.vue?vue&type=script&lang=js"
export * from "./Forgot.vue?vue&type=script&lang=js"
import style0 from "./Forgot.vue?vue&type=style&index=0&id=40abd44b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40abd44b",
  null
  
)

export default component.exports
<template>
  <v-form ref="form" lazy-validation class="d-flex justify-center" style="flex-direction: column;">
    <h4 class=" text-center text-uppercase black--text py-5">Register</h4>
    <p class="text-bold black--text py-5" style="line-height:1.5em;">
      We have sent you a confirmation email at given email address
    </p>
    <v-text-field v-model="email" label="" :disabled="true"></v-text-field>

    <v-btn style="background-color:rgb(29 75 144); color:#fcfcfc;" @click="resend_code" :disabled="requestSent">
      Resend Verification Email
    </v-btn>

    <v-btn style="background-color:rgb(29 75 144); color:#fcfcfc;" class="my-4" :to="{ name: 'Login' }">
      Go To Login
    </v-btn>
    <div>{{ status }}</div>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      requestSent: false,
      status: ""
    };
  },
  methods: {
    resend_code() {
      console.log("Sending request to resend_verification_code");
      this.requestSent = true;
      this.axios
        .post("/api/users/resend_verification", {
          email: this.email,
        })
        .then((res) => {
          console.log(res.data);
          this.requestSent = false;
          this.status = res.data.message;
        })
        .catch((err) => {
          console.log(err);
          this.requestSent = false;
          this.status = "There was an error while resending the code";
        });
    },
  },
  created() {
    this.email = this.$route.params.email;
  },
};
</script>

module.exports.appbar = {
  state: () => ({
    pageHeading: "",
  }),
  getters: {
    getHeading: (state) => {
      return state.pageHeading;
    },
  },
  mutations: {
    ["SET_HEADING"](state, heading) {
      state.pageHeading = heading;
    },
  },
  actions: {
    setHeading({ commit }, heading) {
      commit("SET_HEADING", heading);
    },
  },
};

<template>
    <div>
        
    </div>
</template>
<script>
export default {
    created(){
        localStorage.removeItem("token");
        this.$router.push({ name: 'Login' })
    }
}
</script>
<template>
  <v-form ref="form" v-model="valid" lazy-validation class="d-flex justify-center" style="flex-direction: column;">
    <h4 class=" text-center text-uppercase black--text py-5">Register</h4>
    <v-text-field v-model="firstName" :rules="firstNameRules" label="First Name" required solo dense></v-text-field>
    <v-text-field v-model="lastName" :rules="lastNameRules" label="Last Name" required solo dense></v-text-field>
    <v-text-field v-model="email" :rules="emailRules" label="E-mail" required solo dense></v-text-field >
    <v-text-field v-model="password" :rules="passwordRules" label="Password" required type="password" solo dense></v-text-field>
    <v-text-field v-model="repeatPassword" :rules="repeatPasswordRules" label="Repeat Password" required type="password" solo dense></v-text-field>

    <v-btn style="background-color:rgb(29 75 144); color:#fcfcfc;" class="my-4" @click="validate" :disabled="!valid || request_sent">
      Register
    </v-btn>
    <div>{{ status }}</div>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repeatPassword: "",
    firstNameRules: [(v) => !!v || "First name is required", (v) => (v && v.length >= 2) || "First name should min 2 characters"],
    lastNameRules: [(v) => !!v || "Last name is required", (v) => (v && v.length >= 2) || "Last name should min 2 characters"],
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    passwordRules: [(v) => !!v || "Password is required", (v) => (v && v.length >= 8) || "Password should be greater than 8 characters"],
    repeatPasswordRules: [(v) => !!v || "Repeat Password is required", (v) => (v && v.length >= 8) || "Passwords do not match"],

    status: "",
    valid: true,
    request_sent: false,
  }),
  methods: {
    validate() {
      // validating
      let validated = this.$refs.form.validate();

      // checking for password equality
      if (!validated || this.password !== this.repeatPassword) {
        this.status = "Passwords do not match";
        return;
      }

      // sending request to api
      this.request_sent = true;
      this.axios
        .post("/api/users/register", {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          repeatPassword: this.repeatPassword,
        })
        .then((res) => {
          this.request_sent = false;
          this.status = res.data.message;
          if (res.data.success) {
            this.$router.push({ name: "RegisterConfirmEmail", params: { email: this.email } });
          }
        })
        .catch((err) => {
          this.request_sent = false;
          this.status = "There was an error while sending request for user registeration";
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  created() {},
};
</script>

<style scoped>
::v-deep .small-font,
::v-deep .v-label,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}
</style>

<template>
  <v-form ref="form" lazy-validation class="d-flex justify-center" style="flex-direction: column;">
    <h4 class=" text-center text-uppercase black--text py-5">Verification</h4>
    <p class="text-bold black--text py-5 .small-font" style="line-height:1.5em;">
      Account verification is pending by Site Admin, request will be accepted soon.
    </p>

    <v-btn style="background-color:rgb(29 75 144); color:#fcfcfc;" class="my-4" :to="{ name: 'Login' }">
      Go To Login
    </v-btn>

    <!-- <v-btn style="background-color:rgb(29 75 144); color:#fcfcfc;" class="my-4" @click="setStatus()">
      Send Admin A Reminder
    </v-btn>

    <p class="text-bold black--text py-5 .small-font" style="line-height:1.5em;">
      {{ status }}
    </p> -->
  </v-form>
</template>

<script>
export default {
  name: "PendingAdminVerification",
  data() {
    return {
      status: "",
    };
  },
  methods: {
    setStatus() {
      // send reminder request
      this.status = "A reminder has been sent to admin for account verification.";
    },
  },
  created() {},
};
</script>

<style scoped>
::v-deep .small-font,
::v-deep .v-label,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
